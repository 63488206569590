import React, {useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "../../components/Header/Header.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
// sections for this page
import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";

import Icon from "@material-ui/core/Icon";

import styles from "../../assets/jss/material-kit-react/views/components.js";
import newClasses from './Views.module.css'


//Redux
import { Provider } from 'react-redux';
import store from '../../store'
import {loadUser} from '../../actions/auth'

import setAuthToken from '../../utils/setAuthToken'


const useStyles = makeStyles(styles);

if(localStorage.token) {
  setAuthToken(localStorage.token)
}

const Components = ({auth: {isAuthenticated, loading }, logout}) => {

  useEffect(() => {
    store.dispatch(loadUser())
  }, []);
  const classes = useStyles();

  // const { ...rest } = props;

  const siteBackground = {
    backgroundImage: 'linear-gradient( 109.2deg,  rgba(107,52,255,1) 16.5%, rgba(51,159,247,1) 81% )'    
  }

  const cardContent = {
    margin: 'auto'
  }


  return (
    <div style={siteBackground}>
          <Header
            brand="peoplewhoarecool.com"
            color="info"
          />
      {/* <Parallax > */}
      {/* image={require("assets/img/pink_confetti.jpg")} */}
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={newClasses.titleNeon}>The PWAC Award</h1>
                <h3 className={classes.subtitle}>
                  The only award <strong>anyone</strong> can win.
                </h3>
                <Card>
                  <CardBody style={cardContent}>
                  <h2><strong>How does people who are cool work?</strong></h2>
                <p>Cast your vote with a:</p>
                <ul>
                  <li>COOL: This guy or gal is cool</li>
                  <li>Booo: This guy or gal is not cool</li>
                  <li>Who cares?: This guy or gal is irrelevant</li>
                </ul>
                <p>Cast your votes and our algorithm will calculate who is the coolest based on the responses.</p>
                <h4>The #1st Place winner at the end of every month will recieve the coveted PWAC award and will forever be the coolest!</h4>
                <a href="/#rankings">
                <Button variant="contained" color="primary" component="span">
                Vote
              </Button>
                    </a>
                    <a href="/#rankings">
              <Link to="/nominate">
                <Button variant="contained" color="primary" component="span">
                Nominate anyone in the world!
              </Button>
              </Link>
                    </a>  
                  </CardBody>
                </Card>
    
              </div>
            </GridItem>
          </GridContainer>
        </div>
      {/* </Parallax> */}

      <div id="rankings" className={classNames(classes.main, classes.mainRaised)}>
        <SectionBasics />
        {/* <SectionNavbars /> */}
        {/* <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples /> */}
        {/* <SectionLogin />  */}
        {/* <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

Components.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps, {logout})(Components)