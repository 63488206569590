import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert';
import { register } from '../../../actions/auth';

import { Link, Redirect } from 'react-router-dom'

import propTypes from 'prop-types'
// import axios from 'axios';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import People from "@material-ui/icons/People";
// import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import Button from "../../../components/CustomButtons/Button.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";

import styles from "../../../assets/jss/material-kit-react/views/componentsSections/loginStyle.js";

//Redux
import { Provider } from 'react-redux';
import store from '../../../store'
import {loadUser} from '../../../actions/auth'
import setAuthToken from '../../../utils/setAuthToken'


const useStyles = makeStyles(styles);
if(localStorage.token) {
  setAuthToken(localStorage.token)
}
const Register = ({ setAlert, register, isAuthenticated }) => {
  useEffect(() => {
    store.dispatch(loadUser())
  }, []);
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: ''
    });

    const { name, email, password, password2 } = formData;

    const handleChange = (e) => {
      console.log("test");
    }

    const onChange = e => {
        // console.log(e)
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onSubmit = async e => {
        e.preventDefault();
        if(password !== password2) {
            setAlert('passwords do not match', 'danger')
        } else {

            register({ name, email, password});

        }
    }
    
    if(isAuthenticated) {
      return <Redirect to='/'/>
    }

    return (
      <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
            <Card>
                <CardBody>
                 <h2>Why?</h2>
                 <p>Registering will help supply serious nominations and provide a point of contact to send the award to any winner!</p>
                </CardBody>
              </Card>
              <form className={classes.form}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Register to nominate anyone!</h4>
                  <p>or</p>
                  <Link to="/login">
                  <Button variant="contained" color="primary" component="span">
                  <h4>Login</h4>
                  </Button>
                  </Link>
                  {/* <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={classes.socialIcons + " fab fa-twitter"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={classes.socialIcons + " fab fa-facebook"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i
                        className={
                          classes.socialIcons + " fab fa-google-plus-g"
                        }
                      />
                    </Button>
                  </div> */}
                </CardHeader>
                {/* <p className={classes.divider}>Or Be Classical</p> */}
                <CardBody>
                  <CustomInput
                    labelText="Name..."
                    id="name"
                    value={name}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                    
                      type: "text",
                      placeholder:'Name',
                      name:'name'
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <People className={classes.inputIconsColor} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    name="email"
                    value={email}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                   
                      type: "email",
                      name:"email"
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <Email className={classes.inputIconsColor} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    value={password}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                    
                      type: "password",
                      name:"password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                <CustomInput
                    labelText="Confirm Password"
                    id="pass2"
                    value={password2}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                  
                      type: "password",
                      name:"password2", 
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" onClick={onSubmit} value="Register">
                    Get started
                  </Button>
                </CardFooter>
              </form>

             
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    )    
}

Register.propTypes = {
  setAlert: propTypes.func.isRequired,
  register: propTypes.func.isRequired,
  isAuthenticated: propTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { setAlert, register })(Register)