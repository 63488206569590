import React from "react";
// nodejs library that concatenates classes
// nodejs library to set properties for components
// @material-ui/core components
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import Button from "../CustomButtons/Button.js";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import classes from './Ranking.module.css'



// const useStyles = makeStyles(styles);


export default function Ranking(props) {
    // const classes = useStyles();
    // const cardClasses = classNames({
    //   [classes.card]: true,
    //   [classes.cardPlain]: plain,
    //   [classes.cardCarousel]: carousel,
    //   [className]: className !== undefined
    // });

    return (
            <Card>
            <CardBody>
                <div className={classes.rankContainer}>
                        <img className={classes.rankImg} src={props.img} alt={props.name}></img>
                        <h2 className={classes.rankingP}>{props.name}</h2>
                        <a href={props.twitter}>
                            <Button className={classes.rankSocial} justIcon round color="info">
                            
                                <i className={classes.socialIcons + " fab fa-twitter"} />
                                                
                            </Button>
                        </a>       
                            {/* <Button className={classes.rankSocial} justIcon round color="info">
                                <i className={classes.socialIcons + " fab fa-instagram"} />
                            </Button>  */}
                        <Button
                            id="cools"
                            name={props.name}
                            className={classes.rankButton}
                            onClick={props.click} 
                            round 
                            color="primary">
                                {/* <p id="cools" className={classes.buttonCount}>{props.cools}</p> */}
                                <AcUnitIcon id="cools" className={classes.icons} /> {props.cools} cools
                        </Button>
                        <Button
                            id="boos"
                            name={props.name} 
                            className={classes.rankButton}
                            onClick={props.click} 
                            round 
                            color="primary">
                            <MoodBadIcon className={classes.icons} /> {props.boos} booos
                        </Button>
                        <Button
                            id="wcs"
                            name={props.name}                            
                            onClick={props.click} 
                            className={classes.rankButton}                            
                            round 
                            color="primary">
                            <LocalHotelIcon className={classes.icons} /> {props.wcs} who cares?
                        </Button>
                </div>
                
            </CardBody>
        </Card>
        
    );
}