import React, {useState} from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'


import { connect } from 'react-redux';
import  {addPeopleTwit} from '../../actions/people'

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import CustomInput from "../CustomInput/CustomInput.js";

import styles from "../../assets/jss/material-kit-react/views/componentsSections/loginStyle.js";


const useStyles = makeStyles(styles);

const NominateTwit = ({addPeopleTwit, isAuthenticated, history}) => {
    const classes = useStyles();    
    const[formData, setFormData] = useState({
        name: "",
    });

    const {
        name
    } = formData;

    // const handleChange = (e) => {
    //     console.log("test");
    //   }
  
      const onChange = e => {
          // console.log(e)
          setFormData({...formData, [e.target.name]: e.target.value})
      }
  
      const onSubmit = async e => {
          e.preventDefault();
          console.log(formData)
  
        addPeopleTwit(formData, history);
  
      }
      const twiticonStyle = {
          height: 'auto'
      }
      
  
      return (
        <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Nominate</h4>
                  </CardHeader>
                  <p className={classes.divider}>Nominate by twitter username</p>
                  <CardBody>
                    <CustomInput
                      labelText="Twitter @"
                      id="name"
                      value={name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange:(e) => onChange(e),                    
                        type: "text",
                        placeholder:'Twitter @',
                        name:'name',
                        endAdornment: (
                          <InputAdornment position="end">
                            <i style={twiticonStyle} className={classes.socialIcons + " fab fa-twitter"} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button simple color="primary" size="lg" onClick={onSubmit} value="Register">
                      NOMINATE
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      )
}

NominateTwit.propTypes = {
    addPeopleTwit: propTypes.func.isRequired,
    isAuthenticated: propTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {addPeopleTwit})(withRouter(NominateTwit))
