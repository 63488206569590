import React from "react";
// plugin that creates slider

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";


// import Paginations from "components/Pagination/Pagination.js";
import styles from "../../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
// import Ranking from "components/Ranking/Ranking.js";
import Rankings from "../../../components/Rankings/Rankings.js";
// import Nominate from "components/Nominate/Nominate.js";
import { Link } from "react-router-dom";
import newClasses from '../Views.module.css'


const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2 className={newClasses.rankTitle}>Today's Ranking</h2>
          <div className={newClasses.buttonFlex}>
          <Link to="/nominate">
            <Button variant="contained" color="rose" component="span">
                  Nominate Someone
            </Button>
          </Link>
          </div>

            <Rankings
            // rankings={rankings}
            // clicked={countChangeHandler}
            />

        </div>
      </div>
    </div>
  );
}

