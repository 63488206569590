import React, {useState} from 'react'
import propTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'


import { connect } from 'react-redux';
import  {addPeople} from '../../actions/people'

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import CustomInput from "../CustomInput/CustomInput.js";

import styles from "../../assets/jss/material-kit-react/views/componentsSections/loginStyle.js";



const useStyles = makeStyles(styles);

const Nominate = ({addPeople, isAuthenticated, history}) => {
    const classes = useStyles();    
    const[formData, setFormData] = useState({
        name: "",
        img: "",
        twitter: ""
    });

    const {
        name,
        img,
        twitter
    } = formData;

    // const handleChange = (e) => {
    //     console.log("test");
    //   }
  
      const onChange = e => {
          // console.log(e)
          setFormData({...formData, [e.target.name]: e.target.value})
      }
  
      const onSubmit = async e => {
          e.preventDefault();
          console.log(formData)
  
        addPeople(formData, history);
  
      }

      const twitLinkStyle = {
        color: 'white'
      }
      
  
      return (
        <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Nominate with Twitter</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                    <Link style={twitLinkStyle} to="/nominate-twitter">                                          
                        <i className={classes.socialIcons + " fab fa-twitter"} />
                      </Link>                                                                      
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.divider}>Or Be Classical</p>
                  <CardBody>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      value={name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange:(e) => onChange(e),                    
                        type: "text",
                        placeholder:'Name',
                        name:'name'
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <People className={classes.inputIconsColor} />
                        //   </InputAdornment>
                        // )
                      }}
                    />
                    <CustomInput
                      labelText="Image URL"
                      id="img"
                      value={img}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange:(e) => onChange(e),                    
                        type: "text",
                        placeholder:'Image URL',
                        name:'img'
                      }}
                    />
                    <CustomInput
                      labelText="Twitter"
                      id="twitter"
                      name="twitter"
                      value={twitter}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange:(e) => onChange(e),                   
                        type: "twitter",
                        name:"twitter",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button simple color="primary" size="lg" onClick={onSubmit} value="Register">
                      Nominate
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      )
}

Nominate.propTypes = {
    addPeople: propTypes.func.isRequired,
    isAuthenticated: propTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {addPeople})(withRouter(Nominate))
