import React, {useState} from 'react'
import {connect} from 'react-redux'
import propTypes from 'prop-types'
import { login } from '../../../actions/auth'
import { Redirect } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import Button from "../../../components/CustomButtons/Button.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";

import styles from "../../../assets/jss/material-kit-react/views/componentsSections/loginStyle.js";

const useStyles = makeStyles(styles);

const Login = ({ login, isAuthenticated }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
});

  const { email, password } = formData;

  // const handleChange = (e) => {
  //   console.log("test");
  // }

  const onChange = e => {
      // console.log(e)
      setFormData({...formData, [e.target.name]: e.target.value})
  }

  const onSubmit = async e => {
      e.preventDefault();
      login(email, password);

  }

  // Redirect if logged in
  if(isAuthenticated) {
    return <Redirect to='/'/>
  }
    return (
        <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Login</h4>
                  {/* <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={classes.socialIcons + " fab fa-twitter"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={classes.socialIcons + " fab fa-facebook"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i
                        className={
                          classes.socialIcons + " fab fa-google-plus-g"
                        }
                      />
                    </Button>
                  </div> */}
                </CardHeader>
                {/* <p className={classes.divider}>Or Be Classical</p> */}
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    name="email"
                    value={email}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                   
                      type: "email",
                      name:"email"
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    value={password}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(e) => onChange(e),                    
                      type: "password",
                      name:"password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" onClick={onSubmit} value="Login">
                    Login
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    )    
}

Login.propTypes = {
  login: propTypes.func.isRequired,
  isAuthenticated: propTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { login })(Login)