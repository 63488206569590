import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./assets/scss/material-kit-react.scss?v=1.8.0";

//Redux
import { Provider } from 'react-redux';
import store from './store'
// import {loadUser} from './actions/auth'

// pages for this product
import Components from "./views/Components/Components.js";
import Register from "./views/Components/auth/Register.js";
import Login from "./views/Components/auth/Login.js";
import Alert from "./components/Alert/Alert.js";
import Nominate from "./components/Nominate/Nominate.js";
import NominateTwit from "./components/Nominate/NominateTwit.js";

import PrivateRoute from "./components/Routing/PrivateRoute.js"


var hist = createBrowserHistory();

// if(localStorage.token) {
//   setAuthToken(localStorage.token)
// }

ReactDOM.render(
  <Provider store={store}>
  <Router history={hist}>
    <Alert />
    <Switch>
      <Route exact path="/" component={Components} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/nominate" component={Nominate} />
      <PrivateRoute exact path="/nominate-twitter" component={NominateTwit} />
      
    </Switch>

  </Router>
  </Provider>,
  document.getElementById("root")
);

// const App = () => {
//   useEffect(() => {
//     store.dispatch(loadUser)
//   }, []);

//   return(
//   <Provider store={store}>
//   <Router history={hist}>
//     <Alert />
//     <Switch>
//       <Route exact path="/" component={Components} />
//       <Route exact path="/register" component={Register} />
//       <Route exact path="/login" component={Login} />
//     </Switch>

//   </Router>
//   </Provider>,
//   document.getElementById("root")
// )};
