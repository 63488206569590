import axios from 'axios';
import { setAlert } from './alert.js';
import {
    GET_PEOPLE,
    PEOPLE_ERROR,
    PEOPLE_SUCCESS,
    PEOPLE_FAIL
} from './types';

// GET ALL PEOPLE

export const getAllPeople = () => async dispatch => {
    try {
        const res = await axios.get('/api/people');

        dispatch({
            type: GET_PEOPLE,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: PEOPLE_ERROR
        })
    }
};

//nominate person
export const addPeople = (formData, history) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }
    const body = JSON.stringify(formData)

    try {
        const res = await axios.post('/api/people', body, config);

        dispatch({
            type: PEOPLE_SUCCESS,
            payload: res.data
        });

        dispatch(setAlert('Person Created'));

        history.push('/');
    } catch (err) {
        const errors = err.response.data.errors
        
        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: PEOPLE_FAIL
        })
    }
};

//nominate person by twitter
export const addPeopleTwit = (formData, history) => async dispatch => {
    
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const body = JSON.stringify(formData)
    
        try {
            const res = await axios.post('/api/people/twitter', body, config);
    
            dispatch({
                type: PEOPLE_SUCCESS,
                payload: res.data
            });
    
            dispatch(setAlert('Person Created'));
    
            history.push('/');
        } catch (err) {
            const errors = err.response.data.errors
            
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
            dispatch({
                type: PEOPLE_FAIL
            })
        }
    };

export const addCool = (target) => async dispatch => {
    console.log('cool')
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }

    const body = {
        "name": target.name
    }
    if(target.id === "cools") {
        try {
            await axios.put('/api/people/cools', body, config);
            const res = await axios.get('/api/people');        
    
            dispatch({
                type: GET_PEOPLE,
                payload: res.data
            });
        } catch (err) {
            const errors = err.response.data.errors
    
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
            dispatch({
                type: PEOPLE_ERROR
            })
        }
    } else if (target.id === "boos") {
        try {
            await axios.put('/api/people/boos', body, config);
            const res = await axios.get('/api/people');        
    
            dispatch({
                type: GET_PEOPLE,
                payload: res.data
            });
        } catch (err) {
            const errors = err.response.data.errors
    
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
            dispatch({
                type: PEOPLE_ERROR
            })
        }
    } else if (target.id === "wcs") {
        try {
            await axios.put('/api/people/wcs', body, config);
            const res = await axios.get('/api/people');        
    
            dispatch({
                type: GET_PEOPLE,
                payload: res.data
            });
        } catch (err) {
            const errors = err.response.data.errors
    
            if(errors) {
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
            dispatch({
                type: PEOPLE_ERROR
            })
        }
    }

};