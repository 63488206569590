export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PEOPLE = 'GET_PEOPLE';
export const ADD_PEOPLE = 'ADD_PEOPLE'
export const PEOPLE_ERROR = 'PEOPLE_ERROR';
export const PEOPLE_SUCCESS = 'PEOPLE_SUCCESS';
export const PEOPLE_FAIL = 'PEOPLE_FAIL';
export const ADDCOOL = 'ADDCOOL';
export const ADDBOO = 'ADDBOO';
export const ADDWCS = 'ADDWCS';





