import React, {useEffect} from 'react';
import Ranking from '../Ranking/Ranking.js';
import { connect } from 'react-redux';
import {getAllPeople} from '../../actions/people';
import {addCool} from '../../actions/people';

import propTypes from 'prop-types'

const olStyle = {
    fontSize: 'xx-large'
  }

const Rankings = ({getAllPeople, addCool, people: {people, loading}}) => {
    useEffect(() => {
        getAllPeople();
    }, []);
    return (
        <ol style={olStyle}>
        {
            people.map(person => (
            <li>
                <Ranking
                click={(e) => addCool(e.target)} 
                key={person._id}
                rank={person.rank}
                name={person.name}
                img={person.img}
                twitter={person.twitter}
                cools={person.cools}
                boos={person.boos}
                wcs={person.wcs} 
                />
            </li>

        ))}
        </ol>

        // <Ranking key={person._id}
        //     click={(e) => props.clicked(index, e)}
        //     rank={ranking.rank}
        //     img={ranking.img}
        //     name={ranking.name}
        //     twitter={ranking.twitter}
        //     insta={ranking.insta}
        //     cools={ranking.cools}
        //     boos={ranking.boos}
        //     wcs={ranking.wcs}
        //     key={ranking.id}
        // />
    )

}

Rankings.propTypes = {
    getAllPeople: propTypes.func.isRequired,
    addCool: propTypes.func.isRequired,    
    people: propTypes.object.isRequired
}

const mapStateToProps = state => ({
    people: state.people
})

export default connect(mapStateToProps, {getAllPeople, addCool})(Rankings);