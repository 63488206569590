import {
    GET_PEOPLE,
    PEOPLE_ERROR,
    PEOPLE_SUCCESS,
    PEOPLE_FAIL,
    ADDCOOL
} from '../actions/types'

const initialState = {
    person: {},
    people: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const {type, payload} = action;
    
    switch(type) {
        case GET_PEOPLE:
            return {
                ...state,
                people: payload,
                loading: false
            }
        case PEOPLE_SUCCESS:
            return {
                ...state,
                person: payload,
                loading: false
            }
        case ADDCOOL:
            return {
                ...state,
                people: payload,
                loading: false
            }
        case PEOPLE_ERROR:
        case PEOPLE_FAIL:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default: 
            return state;
    }
}